import styled from "@emotion/styled";

const AnchorWrapper = styled.a`
  textdecoration: none;
  color: inherit;

  "&:hover": {
    color: inherit;
  }

  "&:active": {
    color: inherit;
  }

  "&:visited": {
    color: inherit;
  }
`;

const SEOLinkText = ({
  href,
  text,
  style,
  className,
}: {
  href: string;
  text: string;
  style?: any;
  className?: string;
}) => {
  return (
    <AnchorWrapper href={href} style={style} className={className}>
      {text}
    </AnchorWrapper>
  );
};

export default SEOLinkText;
