import { Component } from "react";
import styled from "@emotion/styled";

import * as ga from "../../../../components/common/UtilsGA";

import {
  LocationOnOutlined,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  YouTube,
  ExpandMore,
} from "@mui/icons-material";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import { imageParser } from "./../../../utils/image.utils";

import KenkoLogo from "../../../icons/KenkoLogo";

// import BottomPattern from "./../../../shared/assets/bottom-pattern.webp";

import Wrapper from "../Wrapper/Wrapper";
import WrapperItem from "../WrapperItem/WrapperItem";

import replaceTextWithComponent from "./../../../libs/replaceTexWithComponent";
import SEOLinkText from "../../SEOLinkText";
import { routes } from "../../../constants";
import { useSelector } from "react-redux";
import { shouldRedirectToNewFlow } from "../../../utils/helper.utils";
import { useViewport } from "../../../hooks";
import { detectMobileApp } from "../../../../components/Onboarding/onboarding.helper";

export const FooterWrapper = styled.div<any>`
  position: relative;

  background: var(--kenkoDeepTeal);
  color: var(--biege50);
  padding: 80px 5vw;

  .footer {
    font-weight: 300;
    padding-top: 20px;

    .footer-logo {
      margin-bottom: 30px;
    }
  }

  .footer-text {
    max-width: 500px;
    min-width: 350px;
    font-size: 10px;
    text-align: justify;
  }

  .footer-main-links {
    width: 90%;
    font-size: 14px;
    line-height: 16px;
  }

  .footer-main-links .section1 {
    font-weight: 300;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    gap: 22px;

    font-size: 16px;
    min-width: 250px;

    .head {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      gap: 5px;

      @media (max-width: 600px) {
        padding-bottom: 10px;
      }
    }

    .store-mobile {
      flex-direction: row;
      gap: unset;
      align-items: flex-end;
    }

    .store-web {
      gap: 4px;
      margin-top: 90px;
    }

    a {
      color: var(--kenkoPureWhite);
      text-decoration: none;
    }

    .highlight-links {
      color: var(--kenkoGolden);

      &:visited {
        color: var(--kenkoGolden);
      }
    }
  }

  .address-section {
    padding-block: 30px;

    .head {
      margin-bottom: 10px;
    }
  }

  .footer-links.plans {
    gap: 20px;

    @media (max-width: 500px) {
      gap: 13px;
    }

    .head {
      padding-bottom: 10px;
    }
  }

  .mobileonly {
    display: none;
  }

  .web-text {
    font-size: 10px;
  }

  .site-links {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    opacity: 0.9;
    margin-top: 5px;
    gap: 25px;
  }

  .web-social {
    margin-top: -16px;
  }

  @media (max-width: 768px) {
    padding: 20px 5vw;

    .webonly {
      display: none;
    }

    .mobileonly {
      display: initial;
    }

    .footer {
      display: block;
    }

    .footer-links-wrapper {
      flex-wrap: wrap;
      justify-content: space-between;

      margin: 20px 0;
    }

    .footer-logo {
      // margin-left: calc(50% - 80px);
      /* margin: 0 auto; */
      display: block;
    }

    .footer-text {
      justify-content: center;
      max-width: 98%;
      text-align: justify;
    }

    .store-mobile {
      /* display: flex;
      flex-direction: row;
      gap: unset;
      align-items: flex-end; */

      gap: 12px;
      max-width: 250px;
      flex-wrap: none;
    }

    .media-icons {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 600px) {
    .section1 {
      max-width: 198px;
    }
  }

  @media (max-width: 538px) {
    .footer-main-links {
      height: auto;
    }

    .media-icons {
      /* flex-wrap: wrap; */
      gap: 20px;
    }
    .app-icons {
      gap: unset;
    }
  }

  @media (max-width: 500px) {
    .footer-links {
      min-width: initial;
      font-size: 14px;

      .head {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .map-image {
      width: 120px;
    }

    .media-icons {
      max-width: unset;
    }
  }

  .terms {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 50px 0;
    padding-top: 50px;
    border-top: 1px solid rgba(113, 164, 165, 0.4);

    @media (max-width: 600px) {
      display: none;
    }

    .heading {
      font-size: 25px;
      font-weight: 700;
    }

    .content {
      font-size: 10px;
      font-weight: 300;
      text-align: justify;
    }
  }

  @media (max-width: 420px) {
    .footer-text {
      min-width: unset;
      max-width: unset;
    }
  }
`;

interface BlockProps {
  children?: unknown | Component<any> | string;
  content?: Array<string>;
  className?: string;
  heading?: string;
  link?: string;
  style?: any;
}

const FooterBlock = ({ children, className }: BlockProps) => {
  return (
    <Wrapper disableFlex className={"footer-links " + className}>
      {children}
    </Wrapper>
  );
};

const MapBlock = ({ className, heading, content, link, style }: BlockProps) => {
  return (
    <>
      <FooterBlock className={className}>
        <a
          style={{
            fontSize: "16px",
            ...(style || {}),
          }}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <div className="head">
            <LocationOnOutlined
              style={{ marginLeft: "-5px", color: "var(--kenkoGolden)" }}
            />{" "}
            {heading}
          </div>
          {content?.map((item: string, index: number) => (
            <div
              style={{ fontSize: "10px", lineHeight: "16px", width: "80%" }}
              key={"item" + index}
            >
              {item}
            </div>
          ))}
        </a>
      </FooterBlock>
    </>
  );
};

const AppStoreBlock = ({ className, heading }: BlockProps) => {
  return (
    <>
      <FooterBlock className={className}>
        <div className="head"> {heading}: </div>

        <Wrapper
          className="media-icons app-icons"
          justify="space-between"
          align="center"
          flex={1}
        >
          <a href="https://play.google.com/store/apps/details?id=com.redkenko.health&hl=en_IN&gl=US">
            <img
              className="store-image"
              width={120}
              height={37}
              src={imageParser("layout/playstore.webp")}
              alt="Kenko Play Store"
              onClick={() => {
                ga.event({
                  action: "web_play_store_app_link_clicked",
                  params: {
                    Page_Url: window.location.href,
                    BannerName: "Footer Section",
                  },
                });
                window?.Moengage?.track_event(
                  "web_play_store_app_link_clicked",
                  {
                    Page_Url: window.location.href,
                    BannerNAme: "Footer Section",
                  }
                );
              }}
            />
          </a>
          &nbsp;
          <a href="https://apps.apple.com/in/app/kenko-all-in-one-health-plans/id1616791973">
            <img
              className="store-image"
              src={imageParser("layout/appstore.webp")}
              alt="Kenko App Store"
              width={120}
              height={37}
              style={{
                borderRight: "0.35px solid #ffffff55",
                borderTopRightRadius: "2px",
                borderBottomRightRadius: "2px",
              }}
              onClick={() => {
                ga.event({
                  action: "web_app_store_app_link_clicked",
                  params: {
                    Page_Url: window.location.href,
                    BannerName: "Footer Links",
                  },
                });
                window?.Moengage?.track_event(
                  "web_app_store_app_link_clicked",
                  {
                    Page_Url: window.location.href,
                    BannerName: "Footer Links",
                  }
                );
              }}
            />
          </a>
        </Wrapper>
      </FooterBlock>
    </>
  );
};

const SocialMediaBlock = ({ className, heading }: BlockProps) => {
  return (
    <>
      <FooterBlock className={className}>
        <div className="head"> {heading}: </div>

        <Wrapper
          className="media-icons"
          justify="space-between"
          align="center"
          flex={1}
          style={{ marginTop: "5px" }}
        >
          <a
            href="https://www.facebook.com/kenkohealth.in/"
            target="_blank"
            aria-label="Find Kenko Health on Facebook"
            rel="noreferrer"
            onClick={() => {
              ga.event({
                action: "web_facebook_footer_icon_clicked",
                params: {
                  Page_Url: window.location.href,
                },
              });
              window?.Moengage?.track_event(
                "web_facebook_footer_icon_clicked",
                {
                  Page_Url: window.location.href,
                }
              );
            }}
          >
            <Facebook />
          </a>

          <a
            href="https://twitter.com/yourkenkohealth"
            target="_blank"
            aria-label="Find Kenko Health on Twitter"
            rel="noreferrer"
            onClick={() => {
              ga.event({
                action: "web_twitter_footer_icon_clicked",
                params: {
                  Page_Url: window.location.href,
                },
              });
              window?.Moengage?.track_event("web_twitter_footer_icon_clicked", {
                Page_Url: window.location.href,
              });
            }}
          >
            <Twitter />
          </a>

          <a
            href="https://www.instagram.com/kenkohealth.in/"
            target="_blank"
            aria-label="Find Kenko Health on Instagram"
            rel="noreferrer"
            onClick={() => {
              ga.event({
                action: "web_instagram_footer_icon_clicked",
                params: {
                  Page_Url: window.location.href,
                },
              });
              window?.Moengage?.track_event(
                "web_instagram_footer_icon_clicked",
                {
                  Page_Url: window.location.href,
                }
              );
            }}
          >
            <Instagram />
          </a>

          <a
            href="https://in.linkedin.com/company/kenko-health/"
            target="_blank"
            aria-label="Find Kenko Health on LinkedIn"
            rel="noreferrer"
            onClick={() => {
              ga.event({
                action: "web_linkedin_footer_icon_clicked",
                params: {
                  Page_Url: window.location.href,
                },
              });
              window?.Moengage?.track_event(
                "web_linkedin_footer_icon_clicked",
                {
                  Page_Url: window.location.href,
                }
              );
            }}
          >
            <LinkedIn />
          </a>

          <a
            href="https://www.youtube.com/channel/UCSR1JJaOegXRExyBLChzggg"
            target="_blank"
            aria-label="Find Kenko Health on YouTube"
            rel="noreferrer"
            onClick={() => {
              ga.event({
                action: "web_youtube_footer_icon_clicked",
                params: {
                  Page_Url: window.location.href,
                },
              });
              window?.Moengage?.track_event("web_youtube_footer_icon_clicked", {
                Page_Url: window.location.href,
              });
            }}
          >
            <YouTube />
          </a>
        </Wrapper>
      </FooterBlock>
    </>
  );
};

const LinksSection = ({ data }: any) => {
  return (
    <>
      {data.links.section1.map((link: any, index: number) => (
        <div
          key={"link-" + index}
          className="footer-main-links section1"
          onClick={() => {
            ga.event({
              action: `web_${link.title}_footer_clicked`,
              params: {
                Page_Url: window.location.href,
              },
            });

            window?.Moengage?.track_event(`web_${link.title}_footer_clicked`, {
              Page_Url: window.location.href,
            });
          }}
        >
          <a href={link.url}>{link.text}</a>
        </div>
      ))}
    </>
  );
};

const PlansSection = ({ data, headClass }: any) => {
  return (
    <>
      <div className={"head " + headClass}>{data.links.headings.section2}</div>

      {data.links.section2?.map((link: any, index: number) => (
        <div
          key={"link-" + index}
          className="footer-main-links"
          onClick={() => {
            ga.event({
              action: "web_kenko_plan_footer_clicked",
              params: {
                Page_Url: window.location.href,
                PlanName: `${link.text}`,
              },
            });
            window?.Moengage?.track_event("web_kenko_plan_footer_clicked", {
              Page_Url: window.location.href,
              PlanName: `${link.text}`,
            });
          }}
        >
          <a href={link.url}>{link.text}</a>
        </div>
      ))}
    </>
  );
};

export const FooterWeb = ({
  className,
  data,
  showPlansSection = true,
}: any) => {
  const utm = useSelector((state: any) => state.utmReducer);
  const newFlow = shouldRedirectToNewFlow(utm);

  return (
    <div className={className}>
      <Wrapper
        spacing="40px"
        align="top"
        flex="0.25"
        className={"footer "}
        justify="space-between"
      >
        <WrapperItem flex="1" className="footer-text">
          <KenkoLogo color="var(--kenkoPureWhite)" className="footer-logo" />

          <WrapperItem className="web-text">
            {newFlow
              ? data?.description?.para1
              : replaceTextWithComponent(
                  data?.description?.para1,
                  /monthly healthcare plans/i,
                  <SEOLinkText
                    key="monthly-plan-text"
                    href={routes.PLANS_PAGE}
                    text="monthly healthcare plans"
                    style={{
                      color: "var(--kenkoGolden)",
                      textDecoration: "underline",
                    }}
                  />
                )}
          </WrapperItem>
          <br />
          <br />

          <MapBlock
            className="webonly address-section"
            heading={data?.links?.headings?.mapBlock}
            link={data?.links?.headings?.mapLink}
            content={data?.links?.headings?.mapContent}
          />

          <div className="webonly">
            <br /> <br />
            <div
              style={{
                fontSize: "10px",
                lineHeight: "15px",
                fontWeight: 300,
                color: "var(--appleGreen200)",
                margin: "12px 0",
              }}
            >
              © Redkenko Health Tech Private Limited. All Rights Reserved.
            </div>
          </div>
        </WrapperItem>

        <div
          className="webonly"
          style={{
            width: 1,
            background: "var(--biege50)",
            opacity: 0.3,
            height: 410,
            marginTop: 25,
          }}
        />

        <FooterBlock className="site-links">
          <LinksSection data={data} />
          <br />

          <SocialMediaBlock
            className="webonly web-social"
            heading={data?.links?.headings?.socialMedia}
          />
        </FooterBlock>

        <FooterBlock className="plans">
          {showPlansSection ? <PlansSection data={data} /> : null}

          <br />
          <AppStoreBlock
            heading={data?.links?.headings?.appLinks}
            className="webonly store-web"
          />
        </FooterBlock>
      </Wrapper>
      <Wrapper className={"terms"}>
        <WrapperItem className="heading">{data.terms.title}</WrapperItem>

        <WrapperItem className="content">{data.terms.content}</WrapperItem>
      </Wrapper>

      <WrapperItem pop bottom="0px" left="0" right="0" className="webonly">
        <img
          src={imageParser("bottom-pattern.webp")}
          alt=""
          width={"100%"}
          height={"32px"}
          style={{ marginBottom: "-6px" }}
        />
      </WrapperItem>
    </div>
  );
};

export const FooterMobile = ({ className, data }: any) => {
  const utm = useSelector((state: any) => state.utmReducer);
  const newFlow = shouldRedirectToNewFlow(utm);

  const matchesXS = useViewport(600).below;

  const { platform } = useSelector((state: any) => state.onboarding);
  const isMobileApp = detectMobileApp(platform);

  const Spacer = () => (
    <div
      style={{
        width: "100%",
        height: 1,
        background: "var(--biege50)",
        opacity: 0.3,
      }}
    />
  );

  return (
    <div className={className}>
      <Wrapper
        spacing="40px"
        align="top"
        flex="0.25"
        className="footer"
        justify="space-between"
      >
        <WrapperItem flex="1" className="footer-text">
          <KenkoLogo color="var(--kenkoPureWhite)" className="footer-logo" />
          <WrapperItem>
            {newFlow
              ? data?.description?.para1
              : replaceTextWithComponent(
                  data?.description?.para1,
                  /monthly healthcare plans/i,
                  <SEOLinkText
                    key="monthly-plan-text"
                    href={routes.PLANS_PAGE}
                    text="monthly healthcare plans"
                    style={{
                      color: "var(--kenkoGolden)",
                      textDecoration: "underline",
                    }}
                  />
                )}
          </WrapperItem>
          <br /> <br /> <br />
          <Wrapper disableFlex className="mobileonly">
            <MapBlock
              className="mobileonly address-section"
              heading={data?.links?.headings?.mapBlock}
              link={data?.links?.headings?.mapLink}
              content={data?.links?.headings?.mapContent}
              style={{ fontSize: "12px" }}
            />

            <br />
            <br />
          </Wrapper>
        </WrapperItem>
        <Spacer />
        <br />
        <Wrapper>
          <Wrapper
            flex={1}
            flexDirection="column"
            justify="space-between"
            className="site-links"
          >
            <LinksSection data={data} />
          </Wrapper>
          <Wrapper
            key="plan-section-mobile"
            flex={1}
            flexDirection="column"
            height={matchesXS ? "120px" : "400px"}
            justify="space-between"
            margin={matchesXS ? "0 0 70px" : "0 0 250px"}
          >
            <PlansSection
              headClass="mobile-plans-head site-links"
              data={data}
            />
          </Wrapper>
        </Wrapper>
        <br />
        <Spacer />
        <br />
        <SocialMediaBlock
          className="store-mobile"
          heading={data?.links?.headings?.socialMedia}
        />

        {/* Hide download app section on app */}
        {isMobileApp ? null : (
          <AppStoreBlock
            heading={data?.links?.headings?.appLinks}
            className={"store-mobile"}
          />
        )}
        <div
          style={{
            fontSize: "10px",
            fontWeight: 600,
            opacity: 0.6,
            color: "var(--appleGreen200)",
          }}
        >
          © Redkenko Health Tech Private Limited. All Rights Reserved.
        </div>
        <br />
        <Spacer />
        <Accordion
          sx={{
            background: "inherit",
            color: "var(--kenkoPureWhite)",
            boxShadow: "none",
            paddingBlock: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: "var(--kenkoPureWhite)" }} />}
            sx={{ paddingLeft: 0 }}
          >
            <Typography
              sx={{ fontFamily: "Poppins", fontWeight: 700, opacity: 0.9 }}
            >
              {data.terms.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: "5px" }}>
            <Typography
              sx={{ fontSize: 14, fontFamily: "Poppins", opacity: 0.8 }}
            >
              {data.terms.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br></br>
        <WrapperItem
          pop
          bottom="0px"
          left="0"
          right="0"
          width="100vw"
          className="mobileonly"
        >
          <img
            src={imageParser("bottom-pattern.webp")}
            alt=""
            width={"100%"}
            height={"25px"}
          />
        </WrapperItem>
      </Wrapper>
    </div>
  );
};
