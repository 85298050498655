const replaceTextWithComponent = (
  str: string,
  text: string | RegExp,
  component: any
) => {
  if (!str || !text || !component) return null;

  const result = str.replace(text, ":").split(":");

  result[2] = result[1];
  result[1] = component;

  return result;
};

export default replaceTextWithComponent;
